/* Core css initialization */

#webpack-dev-server-client-overlay {
  display: none;
}

body {
  margin: 0;
  font-family: "Roboto";
  --grayishBlue: #d1d4dc;
  --grayishBlue2: #e2e3e4;
  --darkBlue: #131722;
  --darkBlue2: #2d3243;
  --white: white;
  --vividBlue: #2962ff;
  --candlePositive: #26a69a;
  --candleNegative: #ef5350;
  --predictionColor: #046fe8;
  --placeholderLight: grey;
  --placeholderDark: darkgrey;
  --sma5Color: #f39c12;
  --sma10Color: #dc7633;
  --sma20Color: #845ec2;
  --sma40Color: #dd33bb;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.App {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

#light {
  background: var(--white);
}

#dark {
  background: var(--darkBlue);
}

.Header {
  width: 100%;
  height: 60px;
}

#light.Header {
  border-bottom: 1px solid var(--grayishBlue2);
}

#dark.Header {
  border-bottom: 1px solid var(--darkBlue2);
}

.Header-1 {
  width: 100%;
  height: 100%;
}

.Footer {
  width: 100%;
  height: 60px;
}

#light.Footer {
  border-top: 1px solid var(--grayishBlue2);
}

#dark.Footer {
  border-top: 1px solid var(--darkBlue2);
}

.Footer-1 {
  width: 100%;
  height: 100%;
}

/* Dashboard page */

.App-header {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: row;
}

.App-header-1 {
  width: 50%;
  height: 100%;
}

.App-header-1 #tradingview_widget_wrapper {
  width: 100%;
  height: 100%;
}

.App-header-1 #tradingview_widget_wrapper div {
  width: 100%;
  height: 100%;
}

.App-header-2 {
  width: 50%;
  height: 100%;
}

.App-header-2 #tradingview_widget_wrapper {
  width: 100%;
  height: 90%;
}

.App-header-2 #tradingview_widget_wrapper div {
  width: 100%;
  height: 100%;
}

.App-body {
  width: 100%;
  height: 600px;
}

.App-body-1 {
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App-body-1 #tradingview_widget_wrapper {
  width: 100%;
  height: 100%;
}

.App-body-1 #tradingview_widget_wrapper div {
  width: 100%;
  height: 100%;
}

.App-footer {
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: row;
}

.App-footer-1 {
  width: 100%;
  height: 400px;
}

.App-footer-1 #tradingview_widget_wrapper {
  width: 100%;
  height: 110%;
}

.App-footer-1 #tradingview_widget_wrapper div {
  width: 100%;
  height: 100%;
}

.App-footer-2 {
  width: 100%;
  height: 400px;
}

.App-footer-2 #tradingview_widget_wrapper {
  width: 100%;
  height: 110%;
}

.App-footer-2 #tradingview_widget_wrapper div {
  width: 100%;
  height: 100%;
}

/* Prediction page */

.Prediction-body {
  width: 100%;
  height: 900px;
  display: flex;
  flex-direction: column;
}

.Prediction-body-1 {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
  white-space: nowrap;
}

#light.Prediction-body-1 {
  border-bottom: 1px solid var(--grayishBlue2);
}

#dark.Prediction-body-1 {
  border-bottom: 1px solid var(--darkBlue2);
}

.Prediction-body-1-body {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 1230px;
}

#light.Prediction-body-1-body {
  border: 1px solid var(--grayishBlue2);
}

#dark.Prediction-body-1-body {
  border: 1px solid var(--darkBlue2);
}

.Prediction-body-2 {
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.Prediction-body-2-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 26px;
  margin: auto;
  width: 1178px;
}

#light.Prediction-body-2-body {
  border: 1px solid var(--grayishBlue2);
}

#dark.Prediction-body-2-body {
  border: 1px solid var(--darkBlue2);
}

/* MUI css override */

@media (min-width: 1536px) {
  #header-1-1 {
    max-width: none !important;
  }
}

@media (min-width: 600px) {
  #header-1-1 {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}

@media (min-width: 600px) {
  #header-1-2 {
    margin-right: 20px !important;
  }
}

#menu-1-1 {
  margin: 20px !important;
}

#menu-1-2 {
  margin-right: 0px !important;
}

@media (min-width: 0px) {
  #header-1-3 {
    display: block !important;
  }
}
